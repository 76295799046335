s<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Detail Unit" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="tab-text">
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-1/4 w-full -mt-1">
              <vs-input class="w-full" label-placeholder="Proyek" v-model="data.nama_proyek" readonly/>
            </div>
            <div class="vx-col sm:w-1/4 w-full -mt-1">
              <vs-input class="w-full" label-placeholder="Properti Kategori" v-model="data.nama_properti_kategori" readonly/>
            </div>
            <div class="vx-col sm:w-1/4 w-full -mt-1">
              <vs-input class="w-full" label-placeholder="Properti Tipe" v-model="data.nama_properti_tipe" readonly/>

            </div>
            <div class="vx-col sm:w-1/4 w-full -mt-1">
              <vs-input class="w-full" label-placeholder="Cluster" v-model="data.nama_cluster" readonly/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-1/4 w-full">
              <vs-input class="w-full" label-placeholder="No.Unit" v-model="data.no_unit" readonly/>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
              <vs-input class="w-full" label-placeholder="Luas Bangunan M2" v-model="data.luas_bangunan" readonly/>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
              <vs-input class="w-full" label-placeholder="Luas Tanah M2" v-model="data.luas_tanah" readonly/>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
              <vs-input class="w-full" label-placeholder="Luas Efektif" v-model="data.luas_efektif" readonly/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-6/12 w-full">
              <vs-input class="w-full" label-placeholder="View" v-model="data.view" readonly/>
            </div>
            <div class="vx-col sm:w-6/12 w-full">
              <vs-input class="w-full" label-placeholder="Lokasi" v-model="data.lokasi" readonly/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-1/2 w-full">
              <vs-input class="w-full" label-placeholder="Keterangan" v-model="data.keterangan" readonly/>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              <vs-input class="w-full" label-placeholder="Spesifikasi" v-model="data.spesifikasi" readonly/>
            </div>
          </div>
          <div class="vx-row mb-3">

          </div>

          <vs-divider> Harga Unit </vs-divider>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-4/12 w-full">
              <label class="ml-1 text-xs">HPP Tanah *</label>
              <v-money class="w-full" v-model="data.hpp_tanah" readonly/>
            </div>
            <div class="vx-col sm:w-4/12 w-full">
              <label class="ml-1 text-xs">HPP Bangunan *</label>
              <v-money class="w-full" v-model="data.hpp_bangunan" readonly/>
            </div>
            <div class="vx-col sm:w-4/12 w-full">
              <label class="ml-1 text-xs">Total HPP</label>
              <v-money class="w-full" :value="totalHpp" readonly/>
            </div>
          </div>

        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Tutup</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'

export default {
  name: 'PropertiUnitDetail',
  components: {
    VMoney
  },
  props: ['isActive', 'item'],
  data () {
    return {
      data: {}
    }
  },
  computed: {
    totalHpp () {
      return (this.data.hpp_tanah || 0) + (this.data.hpp_bangunan || 0)
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.data = this.item
      }
    }
  },
  methods: {
    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
